.dashboard__grid {
    width: 100%;
}

.spinner__container {
    position: relative;
    height: 100%;
    left: 40%;
    /* 
    top: 40%;
    left: 50%;
    position: relative; */
}