.flex-container {
  display: flex;
}

.requiredEmail {
  color: #FF543E;
}

.inputEmail__subtitle {
  font-size: 12px;
}
.errorLine {
  background: #FF543E;
}
.successLine {
  background: #868d89;
}
.successMsg {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.015em;
  color: #28573C;
}
.errMsg {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #ff543e;
}
.MuiLinearProgress-barColorPrimary {
  background: none !important;
}
.fileContainer {
  display: flex;
  padding: 30px 12px 20px 0px;
  height: calc(100% - 50px);
  overflow: hidden;
}
.verticalLine {
  border-left: 1px solid #1a1a1a;
  margin-left: 130px;
  padding-left: 30px;
  padding-right: 5px;
  width: 100%;
  font-size: 14px;
  min-height: 500px;
  overflow: auto;
}

.fileContainer .verticalLine ul,
.fileContainer .verticalLine li {
  margin: revert;
  padding: revert;
}

.fileContainer .verticalLine li.d-block {
  display: block;
}

.fileContainer .verticalLine ul.type-circle {
  list-style-type: circle;
}

.email__input {
  height: 40px;
}

.fileupload-btn {
  margin-bottom: 30px;
}

.head2 {
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #1a1a1a;
}
