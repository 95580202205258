.mapDisplay {
  display: flex;
  flex: 1;
  position: relative;
}
.mapdisplaybuttons {
  display: flex;
  height: 100px;
  flex: 1;
  margin: 16px;
  gap: 10%;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  display: block;
  background: #757575;

  border-radius: 5px;
  height: 10%;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #757575;
}
.mapboxgl-ctrl-top-left {
  left: 343px;
  right: 911px;
}
.map-container-box {
  bottom: 0;
  width: 100%;
}
.mapboxgl-map {
  height: 93%;
  top: 2.6rem;
}
.mapboxgl-ctrl-top-right {
  top: 0.5% !important;
  z-index: 0 !important;
}
.addClient {
  cursor: pointer;
  z-index: 25;
  width: 30%;
}

.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext1 {
  visibility: hidden;
  width: 250px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 50px;
}

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
}

.surveyBtn {
  position: absolute;
  top: 6%;
  z-index: 99;
  left: 50%;
}
.geocoder2 {
  background: #fff;
  border-radius: 7px;
  z-index: 0;
  display: none;
}
.geocoder2 .mapboxgl-ctrl-geocoder {
  min-width: 250px !important;
  width: 100% !important;
}

.iconDetails {
  margin-left: 2%;
  float: left;
  height: 40px;
  width: 40px;
}

.locationDisplay {
  background: #ffffff;
  border: 1.5px solid #dfe3eb;
  box-sizing: border-box;
  border-radius: 7px;
  width: 280px;
  z-index: 25;
}
.marker {
  background: url('../../assets/images/Pin.svg') no-repeat;
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-ctrl-compass {
  display: none !important;
}

.mapboxgl-ctrl .mapboxgl-ctrl-group {
  position: absolute;
  left: 1091px;
}
.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}
.mapboxgl-ctrl-compass {
  display: none;
}
.mapboxgl-ctrl-geocoder--input {
  border-radius: 4px;
  border: 1px solid rgba(26, 26, 26, 0.6) !important;
  padding: 6px 30px;
  background: #fff;
}
.geocoder {
  z-index: 10;
  align-self: center;
  width: 88%;
}
.mapboxgl-ctrl-bottom-right {
  top: 10px;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: 0 0 0 1px #505958 !important;
}

.mapboxgl-ctrl-group button {
  width: 20px !important;
  height: 20px !important;
}

.mapboxgl-ctrl-group button + button {
  border-top: 1px solid #505958 !important;
}

.mapboxgl-ctrl-geocoder--icon-search {
  left: 4px !important;
}
.mapboxgl-ctrl-geocoder {
  min-width: 100% !important;
  width: 100% !important;
}

.mapboxgl-canvas {
  outline: none;
}
