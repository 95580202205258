.pageContainer1,
.dashboardDiv {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 12px;
}
.pageContainer1 {
  padding: 30px 24px 20px 0px;
}
.dashboard-heading {
  display: flex;
  gap: 48px;
}

.dashboard-heading h3,
.dashboard-content h3 {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.01em;
}
.dashboard-heading h3 {
  display: flex;
  align-items: center;
}
.dashboard-content h4 {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
.dashboard-heading h3 div {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.dashboard-content {
  padding: 20px;
  padding-bottom: 0%;
  display: flex;
  flex: 1 1;
  margin-bottom: 64px;
  box-shadow: 0px 0px 4px 0px hsl(0deg 0% 0% / 25%);
  margin-left: 2px;
  background: #ffffff;
  min-height: 500px;
}
.geography-container {
  flex-direction: column;
  display: flex;
  flex: 1;
  gap: 16px;
}
#mapContainer {
  height: 100%;
}
.counterDiv {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
}
.countVal {
  font-size: 35px;
}
.mapHeading {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 20px 30px 0px 0px;
}
.mapDiv {
  position: relative;
  display: flex;
  flex: 1;
}
.firstCard {
  width: 200px;
  border: 1px solid #ccc;
  background: #ccc;
  height: 150px;
}
.secondCard {
  width: 200px;
  border: 1px solid #ccc;
  background: rgb(236, 203, 175);
  height: 150px;
}
.thirdCard {
  width: 200px;
  border: 1px solid #ccc;
  background: rgb(165, 231, 243);
  height: 150px;
}

.locationContainer_img {
  display: flex;
  justify-content: center;
  height: 30px;
}

.continentLocation__block {
  width: 20px;
  height: 20px;
}

.continentLocation__americas {
  background-color: #435354;
}

.continentLocation__emea {
  background-color: #3e7ca6;
}

.continentLocation__apac {
  background-color: #d2785a;
}

.continentLocation__latam {
  background-color: #DBD99A;
}

.continentLocation__label {
  margin: 0px 50px 0px 10px;
}
.loadingspinner {
  position: relative;
}
.Mui-disabled {
  background: #f1f1f1 !important;
}
