.MuiCalendarPicker *{
    color: #1a1a1a !important;
}

.MuiIconButton-root {
    color: #1a1a1a !important;
}

.MuiPickersCalendarHeader-label{
    color: #1a1a1a;
}

.MuiDayPicker-weekDayLabel {
    color: #1a1a1a !important;
}

.MuiPickersDay-root.Mui-selected {
    color: #fff !important;
    background-color: #003F2D !important;
    border: none !important;
}

.MuiPickersDay-root:not(.Mui-selected):not(.Mui-disabled) {
    color: #1a1a1a !important;
}

.MuiPickersDay-root:not(.Mui-selected) {
    border: none !important;
}