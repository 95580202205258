.tooltip {
  cursor: pointer;
  position: relative;
  display: inline-block;
  top: 5.1px;
  margin-left: 6px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  white-space: pre-line;
  padding: 2px 8px 4px;
  font-family: 'Calibre-R';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  visibility: hidden;
  width: 200px;

  background-color: #1A1A1A;

  color: #fff;
  border-radius: 4px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.priceTool .tooltiptext {
  width: 200px;
  padding: 4px 8px;
  top: 150%;
  left: 50%;
  height: fit-content;
  margin-left: -180px;
}

.providerTool .tooltiptext {
  width: 200px;
  top: 100%;
  height: 50px;
  line-height: 20px;
}

.transactionTooltip {
  top: 0;
}

.transactionTooltip .tooltiptext {
  width: 200px;
  top: 91%;
  height: 70px;
  left: 10px;
}
.analyticsTooltip{
  top:8px;
}
.analyticsTooltip .tooltiptext {
  width: 200px;
    top: 91%;
    height: 80px;
    left: 10px;
    text-align: left;
}

.mytransaction .tooltiptext {
  width: 284px;
  font-size: 16px;
  height: fit-content;
  top: 100%;
  left: -130px;
}
