.spinner {
  width: 80px;
  height: 80px;
  height: 100%;
  left: 50%;
  position: relative;
}

.spinner:after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  /* margin: 8px; */
  border-radius: 50%;
  border: 6px solid #009f6f;
  border-color: #009f6f transparent;
  animation: spinner 1.2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
