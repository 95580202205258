#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.map-container {
  /* height: 400px; */
  margin-right: 30px;
}
/* .map {
        width: 100%;
        height: 100%;
      } */
.mapboxgl-map {
  top: 0px !important;
  position: absolute;
}

.marker {
  display: block;
  width: 24px;
  background: none;
  height: 24px;
  border: 1px solid #006f44;
  align-self: center;
  border-radius: 50%;
  /* color: white; */
  background-color: #006f44;
  text-align: center;
  margin-right: 3px;
}

.spinner__container {
  height: 100%;
  top: 40%;
  left: 50%;
  position: relative;
}
