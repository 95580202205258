.backdrop {
  position: absolute;
  background: rgb(26 26 26 / 72%);
  top: 0px;
  width: 100%;
  height: 100%;
}
.MuiBackdrop-root {
  position: fixed !important;
  background-color: #1a1a1ab3 !important;
}
.MuiAccordion-root {
  align-items: unset !important;
  border-radius: 2px !important;
  width: 100%;
  flex-direction: column;
}
.MuiAccordionSummary-root {
  flex-direction: row-reverse !important;
  background: #e6eaea !important;
  padding: 0px;
}
.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}
.MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
}
.MuiAccordionSummary-content {
  align-items: start !important;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionDetails-root {
  margin-top: 4px;
  background: #cad1d3;
  min-height: 300px !important;
  justify-content: center;
  padding: 8px 10px !important;
}
.MuiContainer-root {
  padding: 0px !important;
}
.MuiIconButton-edgeEnd {
  margin-right: 0px !important;
}
.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.root {
  align-items: unset !important;
  width: 100%;
  flex-direction: column;
  top: 49px;
  right: 0px;
  background: #ffffff;
  padding: 16px;
}
