.Toastify__toast {
    width: fit-content;
    height: auto !important;
    max-width: 350px !important;
    padding: 16px;
}

.Toastify__toast--success {
    border: 1px solid #50AF78;
    border-left: 3px solid #50AF78;
    background-color: #E6F4EC;
}

.Toastify__toast--error {
    border: 1px solid #A03530;
    border-left: 3px solid #A03530;
    background-color: #FBEEEE;
}

.Toastify__toast-container {
    top: 45%;
    width: fit-content;
}

.Toastify__close-button {
    min-width: 20px;
    min-height: 20px;
    max-width: 24px;
    max-height: 24px;
    margin-left: 24px;
}

.Toastify__close-button > svg {
    width: 100%;
    height: 100%;
}

.Toastify__close-button > svg path {
    fill: rgba(26, 26, 26, 0.6);
}