@font-face {
  font-family: 'Graphik';
  src: local('Graphik'), url(./fonts/GraphikRegular.otf) format('truetype');
}

@font-face {
  font-family: 'GraphikMedium';
  src: local('GraphikMedium'), url(./fonts/GraphikMedium.otf) format('truetype');
}
@font-face {
  font-family: 'GraphikBlack';
  src: local('GraphikBlack'), url(./fonts/GraphikBlack.otf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Regular'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Calibre-R';
  src: local('Calibre-R'), url(./fonts/calibre-r-web-regular.woff2) format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Calibre-R';
  src: local('Calibre-R'), url(./fonts/calibre-r-web-bold.woff2) format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Calibre-R';
  src: local('Calibre-R'), url(./fonts/calibre-r-web-medium.woff2) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Calibre-R';
  src: local('Calibre-R'), url(./fonts/calibre-r-web-semibold.woff2) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Calibre-R';
  src: local('Calibre-R'), url(./fonts/calibre-r-web-regular-italic.woff2) format('truetype');
  font-style: italic;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Graphik;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
