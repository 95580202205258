#shortlist {
  width: 100%;
  height: 110vh;
  background-color: white;
}
.statusDropdown {
  justify-content: flex-end;
  width: 320px;
}
.statusDropdown select {
  padding-left: 10px !important;
  flex: 1;
}

.shortlistHeader {
  padding: 20px 5px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}

.shortListTable table {
  border-spacing: 0;
  border: 1px solid rgba(0, 63, 45, 0.2);
}

.shortListTable table td, th {
  margin: 0;
  border: 1px solid rgba(0, 63, 45, 0.2);
}

.surveyTable {
  border:none !important;
}

.surveyTable thead {
  position: sticky;
  top: -1px;
  z-index: 100;
  background: #fff;
  box-shadow: inset 0 1px 0 rgb(0 63 45 / 20%), inset 0 -1px 0 rgb(0 63 45 / 20%);
}

.surveyTable thead tr {
  height: 56px;
}

.surveyTable tbody tr {
  height: 52px;
}

.surveyTable td,
.surveyTable th {
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
}
.surveyTable th {
  font-weight: 500;
}

.surveyTable td {
  font-weight: 400;
}

.surveyTable tbody tr:hover {
  cursor: pointer;
  background: rgba(26,26,26,.12);
}

.surveyTable tbody tr:hover td {
  border: none;
}

.leftHeader {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.leftHeader img:hover {
  cursor: pointer;
}

.leftHeader .text {
  font-size: 20px;
  font-weight: 600;
  margin-top: 12px;
}

.btn {
  cursor: pointer;
  color: #003f2d;
  width: 160px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid #003f2d;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 17px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.shortlistedBtn {
  cursor: pointer;
  color: #003f2d;
  width: 160px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  background-color: rgba(0, 63, 45, 0.08);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 17px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}
.shortlist__tabs {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  /* margin-left: 20px; */
  width: 98%;
}

.transaction-tab-container {
  display: flex;
  flex-direction: row;
}
.transaction-tabs {
  flex: 1;
}
.shortlist__tabs ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.shortlist__tabs li {
  font-size: 16px;
  margin-right: 16px;
  font-weight: 500;
  padding: 14px 16px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.shortlist__tabs li:hover {
  background: rgba(26, 26, 26, 0.04);
  box-shadow: inset 0px -1px 0px rgba(0, 63, 45, 0.15);
}

.shortlist__tabs .active {
  border-bottom: 3px solid;
  border-color: #003f2d;
}

.shortListTable {
  height: 550px;
  overflow: auto;
}

.shortListTable::-webkit-scrollbar {
  height: 84px;
  width: 4px;
}

.shortListTable::-webkit-scrollbar-thumb{
  background: rgba(26, 26, 26, 0.12);
  border-radius: 10px;
}
.shortListTable::-webkit-scrollbar-track{
  background: #E6EAEA;
}

.shortListTable .MuiInputBase-colorPrimary {
  display: none;
}

.actionsDiv {
  display: flex;
}

.actionsDiv div {
  margin-left: 20px;
}

.shortListTable::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}
.share {
  font-weight: bold;
  position: absolute;
  font-size: 23px;
  margin-bottom: 2px;
  left: 0;
  padding-left: 10px;
}
.share:before {
  content: '.';
  font-size: 1.7em;
  position: absolute;
  bottom: -2.5px;
  left: 6px;
}
.share:after {
  content: ':';
  font-size: 1.7em;
  position: absolute;
  bottom: -8px;
  right: -3px;
}
.enablebtn {
  cursor: pointer;
}
.disablebtn {
  pointer-events: none;
  background: #ccc;
}
