.kSNiSm table th,
.kSNiSm table td {
  border-right: 0px !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.kSNiSm {
  padding-left: 0px !important;
}
.kSNiSm table {
  border: 0px !important;
  table-layout: fixed !important;
}
.dOWgej {
  padding: 0 !important;
}

.kSNiSm tr:nth-child(even) {
  background-color: #f6f6f6;
}
.kSNiSm thead tr th {
  background: rgba(0, 0, 0, 0.12);
}
.kSNiSm thead tr th:nth-child(4) {
  text-align: right !important;
}
.kSNiSm tbody tr td:nth-child(4) {
  text-align: right !important;
}
.kSNiSm thead tr th:nth-child(5) {
  width: 15% !important;
}
.kSNiSm tbody tr td:nth-child(5) {
  width: 15% !important;
}
.pagination {
  text-align: right;
}
.pageSelect {
  padding-left: 8px;
  text-align-last: right;
  border: 0;
  margin-right: 20px;
  font-size: 14px;
}
.pagination span {
  margin-right: 20px;
}
.pagination button {
  margin-right: 10px;
  border: 0;
  background: none;
  font-size: 14px;
  cursor: pointer;
}

.tableHeaderColumn {
  display: flex;
}

.myTransactionTableContainer table thead {
  position: sticky;
  top: -1px;
  z-index: 100;
  background-color: #fff;
}

.myTransactionTableContainer {
  height: 590px;
  overflow: auto;
}

.myTransactionTableContainer::-webkit-scrollbar {
  height: 84px;
  width: 4px;
}

.myTransactionTableContainer::-webkit-scrollbar-thumb{
  background: rgba(26, 26, 26, 0.12);
  border-radius: 10px;
}
.myTransactionTableContainer::-webkit-scrollbar-track{
  background: #E6EAEA;
}

.sortedImage__block {
  display: flex;
  align-items: center;
}

.psize {
  text-align: right;
}

.tableSurveysBody__row {
  width: 12%;
}

.surveyStatus__row {
  width: 8%;
}

.viewDetailsButton {
  color: #003F2D;
  padding: 0;
  margin: 0;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  background: inherit;
  cursor: pointer;
}
.sortImg {
  margin-left: 3px;
}

.jvCTkj table th,
.jvCTkj table td {
  border-right: 0px !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.jvCTkj {
  padding-left: 0px !important;
}
.jvCTkj table {
  border: 0px !important;
  table-layout: fixed !important;
}

.jvCTkj tr:nth-child(even) {
  background-color: #f6f6f6;
}
.jvCTkj thead tr th:nth-child(4) {
  text-align: right !important;
}
.jvCTkj tbody tr td:nth-child(4) {
  text-align: right !important;
}
.jvCTkj thead tr th:nth-child(5) {
  width: 15% !important;
}
.jvCTkj tbody tr td:nth-child(5) {
  width: 15% !important;
}
.jvCTkj thead tr th {
  background: rgba(0, 0, 0, 0.12);
}
/* .jvCTkj tbody tr:last-child {
   display:none;
  } */
