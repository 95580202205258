.MuiCalendarPicker * {
  color: #1a1a1a !important;
}

.MuiInputLabel-root {
  color: #1a1a1a !important;
}

.MuiPickersCalendarHeader-label {
  color: #1a1a1a;
}

.MuiDayPicker-weekDayLabel {
  color: #1a1a1a !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: #003f2d !important;
  border: none !important;
}

.MuiPickersDay-root:not(.Mui-selected):not(.Mui-disabled) {
  color: #1a1a1a !important;
}

.MuiPickersDay-root:not(.Mui-selected) {
  border: none !important;
}
.MuiPickersCalendarHeader-labelContainer {
  max-height: 60px !important;
}
