.App {
  background: #f5f7f7;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

h1 {
  font-size: 1em;
}
h3 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 400;
}
h4 {
  font-size: 34px;
  line-height: 40px;
  font-weight: 400;
}
h5 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin: 16px 0px;
}
h6 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin: 16px 0px;
}

.font-400 {
  font-weight: 400 !important;
}

.mt-20 {
  margin-top: 20px;
}
.product-title {
  font-weight: bold;
  font-size: 1.2em;
}

.product-id {
  font-size: 0.8em;
  margin: 1em 0;
}

.product-edit-icon {
  opacity: 0.7;
  position: absolute;
  right: 2.3rem;
  top: 0.4rem;
}

.product-edit-icon:hover {
  opacity: 1;
}

.features {
  padding-bottom: 3em;
}

body * {
  font-family: 'Calibre-R' !important;
}
body {
  color: #1a1a1a;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
}

::-webkit-scrollbar {
  height: 84px;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(26, 26, 26, 0.12);
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: #e6eaea;
}
a {
  font-family: 'Calibre-R';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: #003f2d underline;
  color: #003f2d;
}

a:visited {
  color: #262698 !important;
  text-decoration: #262698 underline;
}

a:hover {
  text-decoration: #17e88f underline;
}

a:active {
  color: #3d6d5f !important;
  text-decoration: #3d6d5f underline;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
  color: #1a1a1a !important;
  background-color: #fff !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.mapboxgl-ctrl-geocoder .suggestions .active a {
  background-color: #ededed !important;
}

.mapboxgl-ctrl-geocoder .suggestions a:hover {
  background-color: rgba(26, 26, 26, 0.12) !important;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: 500 !important;
}

.mapboxgl-ctrl-zoom-out span {
  padding-bottom: 1px;
}

.error {
  width: 300px;
  color: #db5030;
}
.error a {
  text-decoration: none !important;
  color: #db5030;
}

ul,
li {
  margin: 0;
  padding: 0;
}
.content {
  width: 100%;
  height: calc(100% - 50px);
  overflow: hidden;
}
.navbar-brand {
  padding: 10px;
}

.provider.content {
  padding-top: 50px;
  display: flex;
  gap: 24px;
  flex-direction: row;
}
.mk2.content {
  padding-top: 56px;
  display: flex;
  gap: 24px;
  flex-direction: row;
}

.provider.rightbar,
.mk2.rightbar {
  display: flex;
  flex: 1;
}
.rightbar .rightbarcontent {
  overflow: auto;
  /* display: flex; */
  flex: 1;
}
.curtain {
  z-index: 2;
  position: absolute;
}

.auth {
  margin: 20px auto;
  width: 500px;
  text-align: center;
  padding: 10px;
}
.auth form {
  margin-top: 1em;
  max-width: 50%;
}

.button {
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.02em;
  padding: 8px 16px;
  border-radius: 4px;
  border: 2px solid transparent;
}

.button.primary {
  background: #003f2d;
  color: #ffffff;
}
.button:disabled {
  cursor: not-allowed !important;
}
.button.primary:disabled {
  background: rgba(26, 26, 26, 0.12) !important;
  color: rgba(26, 26, 26, 0.46) !important;
}
.button.primary:hover {
  color: #ffffff;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #003f2d;
  cursor: pointer;
}
.button.primary:focus {
  color: #ffffff;
  background: #003f2d;
  box-shadow: 0px 0px 2px 2px #003f2d;
}
.button.secondary {
  color: #003f2d;
  border: 1px solid #003f2d !important;
  background: #ffffff;
}
.button.secondary:hover {
  background: rgba(0, 63, 45, 0.08);
  border-color: #003f2d;
  cursor: pointer;
}
.button.secondary.disabled {
  color: rgba(26, 26, 26, 0.46) !important;
  border: 1px solid rgba(26, 26, 26, 0.46) !important;
  cursor: not-allowed;
}
.button.secondary:focus {
  border: 4px solid #17e88f;
  opacity: 0.6;
}

.button.text {
  color: #003f2d;
  border: none;
  background: none;
}
.button.text:hover {
  color: #003f2d;
  background: #003f2d14;
}

.button.text.disabled {
  color: #1a1a1a46;
}
.button.text:focus {
  color: #003f2d;
  border: 2px solid #003f2d;
  background: #ffffff;
}

.button.light {
  color: #003f2d;
}
.button.light:hover {
  color: #003f2d;
  background: #1a1a1a04;
}
.button.light.disabled {
  color: #1a1a1a46;
}
.button.light:focus {
  color: #003f2d;
  border: 4px solid #17e88f;
}

/* For hiding "Powered by Google in auto complete" */
.hdpi.pac-logo:after {
  display: none !important;
}

/* For adding box shadow to differentiate dropdown with bottom content in the Application level */
.MuiAutocomplete-popper {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}
