.flex-container {
  display: flex;
  margin-bottom: 20px;
}
.item {
  padding: 10px;
}
.ml-auto {
  margin-left: auto;
  display: flex;
  gap: 80px;
}
.dropbtn {
  background-color: #003f2d;
  color: white;
  padding: 6px 16px 6px 12px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 100;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: pointer;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #003f2d;
}
.MuiIconButton-sizeSmall {
  /* padding: 0px 5px 8px 10px !important; */
  margin: 0px 5px 8px 30px !important;
}
.ForwardRef\(TableCell\)-root-9 {
  width: none !important;
}
.locationsTable {
  border: 1px solid #ccc;
}
.sortedLocationImage__Icon {
  cursor: pointer;
  padding: 2px;
  position: absolute;
}

.loadingspinner {
  margin-top: 20px;
}
.muiCustomMenuStyles {
  padding: 6px 16px !important;
  display: flex !important;
  justify-content: left !important;
}
.noRow {
  display: inline-flex;
  align-items: center;
  column-gap: 5px;
  padding: 25px;
  padding-left: 0;
}

.reasonSelect option {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 8px;
  border-radius: 8px;
  z-index: 1000;
  padding: 5px 10px;
  cursor: pointer;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 6px !important;
}
/* //search close icon changes */
.MuiIconButton-root {
  padding: 5px !important;
}
