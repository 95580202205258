.uploadPage {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.uploadPage .titletext {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: #1a1a1a;
}
.uploadPage .info {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  /* width: 400px; */
  margin-top: 10px;
}

.fileUploadContainer {
  width: 600px;
}

.emailError__block {
  font-size: 16px;
  color: rgb(179, 70, 30);
}

.withoutError__block {
  height: 6px;
}

.heading {
  color: black;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}
.uploadDiv {
  border: 1px solid #ccc;
  height: 200px;
  width: 600px;
  margin-top: 30px;
}
.uploadBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px 6px 12px;
  position: absolute;
  width: 162px;
  height: 36px;
  left: 210px;
  top: 44px;
  background: #009f6f;
  border-radius: 2px;
  color: #fff;
}
.uploadText {
  text-align: center;
  margin-top: 70px;
}
.my20 {
  margin-top: 20px;
  width: 648px;
}
.bar {
  border-radius: 0;
  height: 5px;
}
.emailField {
  width: 100%;
}
.successModal {
  width: 600px;
  height: 170px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 4px 4px 4px 4px rgb(0 0 0 / 25%);
}
.successMsg1 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.thankyouMsg {
  font-weight: bold;
  margin-top: 0px;
  margin-left: 6px;
  font-size: 18px;
}
