.header {
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
  filter: alpha(opacity=80);
  position: fixed;
  width: 100%;
  margin: 0;
  z-index: 9;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.14);
}
.flexpert-name {
  padding: 1px 5px;
  gap: 16px;
  align-items: center;
}

.nameDiv {
  /* margin: 9px 9px 0px 0px; */
}
.nameuser {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #3c4242;
}
.navRight {
  gap: 8px;
}

.flexpertImg {
  position: relative;
  top: 5px;
  margin-top: -5px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 50%;
}
.emailImg {
}
.navbar,
.navbar-end,
.navbar-menu,
.navbar-start {
  display: flex;
  background: #fff;
  margin-right: 5px;
}
.navbar {
  background: #fff;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.navbar-menu {
  justify-content: space-between;
}

.navbar-start {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  font-feature-settings: 'case' on;
  color: #3c4242;
  margin-top: 17px;
}

.navbar-end {
  justify-content: space-between;
  column-gap: 24px;
}

.navbar-end div {
  display: flex;
  position: relative;
}

.dropbtn {
  /* background-color: #4CAF50; */
  color: 000;
  padding: 16px 8px;
  /* padding-left:5px; */
  font-size: 16px;
  border: none;
  background: #fff;
  margin-right: 3px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* background-color: #f1f1f1; */
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  z-index: 1;
  right: 0;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none !important;
  display: block;
  background-color: #fff;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #009f6f;
}

.CBRELogo {
  color: #003f2d;
  font-weight: 500;
  font-size: 20px;
}
.logoutBtn {
  cursor: pointer;
  color: #fff;
  border: 0;
  width: 90px;
  height: 30px;
  background: rgb(179, 70, 30);
  box-shadow: 0px 1px 2px rgb(0 114 81 / 25%);
  border-radius: 7px;
  font-weight: bold;
  text-transform: capitalize;
}
.navbar-item {
  display: flex !important;
  text-decoration: none !important;
  border: 1px solid #dfe3eb;
  box-sizing: border-box;
  border-radius: 5px;
  flex-direction: row;
}
.navbar-help-menu {
  align-items: center;
}

.navbar-help-menu a img {
  cursor: pointer;
}
.navbar-help-link {
  display: flex;
  gap: 4px;
  cursor: pointer;
  text-decoration: none !important;
  align-items: center;
}
