.prev, .next {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.carousel-item:nth-child(n){
  display: none;
}

.carousel-item:first-child {
  display: block;
}
