.register-photo {
  /* background: #f1f7fc;
    padding: 80px 0 */
  font-family: Roboto;
  font-style: normal;

  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
.requestdiv {
  width: 94%;
  height: 50px;
  border-radius: 2px;
  margin-top: 10px;
}
/* select.selectdiv  {
    background-position:
    calc(100% - 7px) calc(1.7em + -10px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - .5em) .5em;
} */

.register-photo .image-holder {
  display: table-cell;
  width: auto;
  background: url('../../assets/images/Content_interior_7 1.svg');
  background-size: cover;
}
.or-container {
  align-items: center;
  color: #ccc;
  display: flex;
  margin: 25px 0;
}

.line-separator {
  background-color: #ccc;
  flex-grow: 5;
  height: 1px;
}

.or-label {
  flex-grow: 1;
  margin: 0 15px;
  text-align: center;
}

.register-photo .form-container {
  display: table;
  /* max-width: 900px; */
  width: 100%;
  margin: 0 auto;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.register-photo form {
  display: table-cell;
  width: 500px;
  background-color: #ffffff;
  padding: 30px 40px;
  color: #505e6c;
}

@media (max-width: 991px) {
  .register-photo form {
    padding: 40px;
  }
}
.subHead {
  font-size: 14px;
  font-family: Roboto;
  color: #3d6852;
  font-style: normal;
}

.emailAccess__input {
  width: 500px;
}

.register-photo form h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #1a1a1a;
}

.register-photo form .form-control {
  background: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  text-indent: 0px;
}

.register-photo form .form-check {
  font-size: 13px;
  line-height: 20px;
}

.register-photo form .btn-primary {
  background: #3d6852;
  /* border: none; */
  border-radius: 4px;
  padding: 12px;
  /* box-shadow: none; */
  margin-top: 20px;
  text-shadow: none;
  outline: none !important;
  color: #fff;
  width: 364px;
}
.register-photo form .form-group {
  margin: 15px 0px;
}
.btn-signup {
  border: none !important;
  cursor: pointer;
}

.register-photo form .btn-primary:hover,
.register-photo form .btn-primary:active {
  background: 006F44;
}

.register-photo form .btn-primary:active {
  transform: translateY(1px);
}

.register-photo form .already {
  display: block;
  text-align: left;
  font-size: 12px;
  color: #6f7a85;
  opacity: 0.9;
  text-decoration: none !important;
  margin-top: 10px;
}

.register-photo form .btn-primary1 {
  background: #3d6852;
  /* border: none; */
  border-radius: 4px;
  padding: 12px;
  /* box-shadow: none; */
  margin-top: 20px;
  text-shadow: none;
  outline: none !important;
  color: #fff;
  width: 124px;
  display: inline-block;
  margin-right: 76px;
  background: #009f6f;
}
.register-photo form .form-group {
  margin: 10px 0px;
}

.register-photo form .btn-primary1:hover,
.register-photo form .btn-primary1:active {
  background: 006F44;
}

.register-photo form .btn-primary1:active {
  transform: translateY(1px);
}

.clearBtn {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #009f6f !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  background: #fff !important;
  margin-right: 30px;
}
.imgLogo {
  text-align: center;
  margin-bottom: 52px;
  margin-top: 97px;
  width: 90%;
}

.autocompleteField__container {
  width: 300px !important;
  margin-top: 10px !important;
}

.requestProviderError__block {
  font-size: 14px;
  color: #FF543E;
  margin: 3px;
}

.requestAccess__button {
  width: 500px !important;
  display: block;
}