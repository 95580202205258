.surveys {
  font-family: Roboto;
}
.dashboard {
  background: #fff;
  padding: 17px;
  padding-top: 0px;
  width: 100%;
  margin-right: 17px;
  margin-top: 17px;
  overflow-y: auto;
  margin-bottom: 50px;
}
.dashboardHead {
  margin-bottom: 20px;
}

.heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.015em;
  color: #000000;
}

.transactionWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 50px;
}
.cards {
  display: flow-root;
}

.card {
  border: solid 1px rgba(0, 0, 0, 0.12);
  /* border-left: solid 6px rgba(0, 0, 0, 0.12); */
  margin-right: 60px;
  margin-bottom: 10px;
  float: left;

  background: #ffffff;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 14%);
  width: 256px;
  height: 144px;
}

.card:hover {
  /* border: solid 1px #195141;
  border-left: solid 6px #195141; */
  cursor: pointer;
  background: rgba(0, 63, 45, 0.08);
  /* Border/Bottom */

  box-shadow: inset 0px -1px 0px rgba(0, 63, 45, 0.15);
}

.image {
  text-align: center;
  margin-top: 5%;
}

.lineBreak {
  /* height: 34px; */
  height: 20px;
}

.quantity {
  font-size: 30px;
  font-weight: 400;
  color: #000;
  text-align: center;
  margin-top: 5%;
  display: inline-block;
  margin-left: 15px;
}
.subTitle {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: rgba(26, 26, 26, 0.6);
}

.title {
  text-align: center;
  color: #000;
  margin-top: 27px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.stitle {
  text-align: center;
  color: #000;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.surveyHead {
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin-left: 40px;
}

/* .agileTable {
  margin-top: 20px;
  margin-left: 40px;
  max-height: 45%;
  table-layout: fixed;
  width: 100%;
}

.agileTable thead tr {
  height: 56px;
  background-color: '#CCCCCC' !important;
}

.agileTable tbody tr td{
  height: 52px;
}

.agileTable tbody tr td,
.agileTable thead tr th {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  font-weight: 500;
  color: black;
  width: 350px !important;
}
.agileTable th {
  font-weight: 600;
} */
.searchBar__container {
  width: 260px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.activeCard .card {
  cursor: pointer;
  /* border: solid 1px #003f2d; */
  /* border-left: solid 6px #003f2d; */
  background: rgba(0, 63, 45, 0.08);
  /* Border/Bottom */

  box-shadow: inset 0px -1px 0px rgba(0, 63, 45, 0.15);
}
.searchBlock {
  justify-content: space-between;
}

.year {
  padding: 10px 16px;
}
.sortedImage__Icon {
  text-align: right;
  cursor: pointer;
  float: left;
}
.sortOrderImg {
  float: right;
  cursor: pointer;
}

.pagination {
  list-style: none;
  text-align: right;
}
.spinner {
  height: 100%;
  top: 50%;
  left: 50%;
}

.pagination li {
  display: inline-block;
  margin-left: 30px;
}

.arrow {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.54);
  padding: 3px;
  font-size: 16px;
  width: 2px;
  height: 2px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.rightAligned {
  text-align: right;
  cursor: pointer;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.pagination li div i:hover {
  font-size: 18px;
  cursor: pointer;
  color: #009f6f !important;
  width: 3px;
  height: 3px;
  border-width: 0 3px 3px 0;
}
.tooltip {
  cursor: pointer;
}
.surveyTooltip {
  visibility: hidden;
  width: 284px;
  height: fit-content;
  background-color: #2b3836;
  color: #fff;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.8px;

  /* Position the tooltip */
  /* position: absolute; */
  margin-top: 5px;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -5px;
}
.surveyTooltip span {
  width: 100%;
}
.subText {
  color: lightgray;
  font-weight: 500 !important;
}

.tooltip:hover .surveyTooltip {
  visibility: visible;
}
.searchBox {
  margin-left: 40px;
  margin-top: 20px;
  position: relative;
}
.searchBox i {
  position: absolute;
  left: 15px;
  top: 40px;
  color: gray;
}
.searchInput {
  border-radius: 4px;
  border: 1px solid;
  padding: 10px;
}
.searchInput a:hover {
  background-color: #ddd;
  color: black;
}

.container {
  display: flex;
  justify-content: flex-end;
  /* background-color: DodgerBlue; */
}

.container > div {
  /* background-color: #f1f1f1; */
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

.analyticsTable table {
  border-spacing: 0;
  border: 1px solid rgba(0, 63, 45, 0.2);
}

.analyticsTable table tbody tr td,
table thead tr th {
  /* margin: 0; */
  border: 1px solid rgba(0, 63, 45, 0.2);
  height: 45px;
  /* width: 10%; */
  padding: 5px;
  font-size: 14px;
}

.analyticsTable::-webkit-scrollbar {
  height: 84px;
  width: 4px;
}
.analyticsTable::-webkit-scrollbar-thumb {
  background: rgba(26, 26, 26, 0.12);
  border-radius: 10px;
}
.analyticsTable::-webkit-scrollbar-track {
  background: #e6eaea;
}

.analyticsTable .MuiInputBase-colorPrimary {
  display: none;
}
.analyticsTable::-webkit-scrollbar {
  height: 3px;
  width: 3px;
}

.actionsDiv {
  display: flex;
}

.actionsDiv div {
  margin-left: 20px;
}
