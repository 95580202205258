.leftBar {
  width: 72px;
  background-color: #f1f1f1;
}
.providerLeftBar {
  width: 280px;
  height: 100%;
  background-color: #fff;
  box-shadow: 2px 2px 4px #00000040;
}
.mk2 .nav a div {
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background-color: #ffffff;
}
.locations {
}
.locations div {
  background: url('../../assets/images/newMapIcon_green.svg') center no-repeat;
}
.locations:hover div,
.locations.active div {
  background: url('../../assets/images/newMapIcon_white.svg') center no-repeat;
}
.providers {
}
.providers div {
  background: url('../../assets/images/dashboard_green.svg') center no-repeat;
}
.analytics div {
  background: url('../../assets/images/agile_green.svg') center no-repeat;
}
.analytics.active div {
  background: url('../../assets/images/agile_white.svg') center no-repeat;
}
.providers:hover div,
.providers.active div {
  background: url('../../assets/images/dashboard_white.svg') center no-repeat;
}
.locations:hover div,
.providers:hover div {
  background-color: #195141 !important;
}
.analytics:hover div {
  background-color: #195141 !important;
}
.active div {
  background-color: #003f2d !important;
}
.active img {
  color: white !important;
}

.mk2.leftBar .nav {
  padding: 16px 8px;
}
.nav li {
  list-style: none;
  margin: 0;
}

.nav li {
}

.nav li div {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  cursor: pointer;
}

.nav li div button {
  margin-bottom: 0 !important;
  margin-left: auto !important;
  padding: 0 !important;
}

.nav ul {
  padding: 0;
}
.nav ul a {
  text-decoration: none !important;
}

.iconBox {
  background-color: white;
  text-align: center;
  margin-bottom: 16px;
  border-radius: 7px;
  align-items: center;
  display: flex;
  border: 0px;
}
.iconBox2 {
  padding: 12px 16px;
  display: flex;
  color: #1a1a1a;
  line-height: 20px;
  font-size: 16px;
}
.iconBox2Border {
  border-left: solid 1px transparent;
  border-left-width: 3px;
}
li a div {
  border-left: 1px solid transparent;
  border-left-width: 3px;
}
.active1 div {
  border-left: 1px solid #003f2d;
  border-left-width: 3px;
  background-color: #e0e8e5;
  cursor: pointer;
  color: #003f2d;
  border-radius: 0;
}

.subMainMenuActive1 div {
  color: #003f2d;
  font-weight: 500 !important;
}

.image {
  display: inline;
  margin-left: 50%;
  margin-top: 50%;
  transform: translate(-50%, -50%);
}
.subMenuLeftNav li {
  padding: 12px 5px 12px 16px;
  margin-bottom: 2px;
  border-left: 1px solid transparent;
  border-left-width: 3px;
}
.subMenuLeftNav li a {
  color: #1a1a1a !important;
  font-weight: normal;
  background-color: transparent;
}
.subMenuLeftNav li .subMenuActive {
  color: #003f2d !important;
}
.subMenuLeftNav li.active1 {
  border-left: 1px solid #003f2d;
  border-left-width: 3px;
  background-color: #e0e8e5;
  cursor: pointer;
  color: #003f2d;
  border-radius: 0;
}
.iconBox2:hover,
.subMenuLeftNav li:hover {
  background-color: #f6f6f6;
}
.muiCollapseVertical div {
  border: none !important;
}
