.surveys {
  font-family: Roboto;
}

.heading {
  margin-left: 40px;
  font-size: 24px;
  font-weight: 500;
  color: black;
}

.transactionWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.cards {
  display: flow-root;
}

.card {
  width: 200px;
  height: 110px;
  border-radius: 8px;
  border: solid 1px #C9D2D7;
  border-left: solid 6px #C9D2D7;
  margin-right: 60px;
  margin-bottom: 10px;
  float: left;
}

.card:hover {
  border: solid 1px #195141;
  border-left: solid 6px #195141;
  cursor: pointer;
}

.searchBar__container {
  width: 260px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.lineBreak {
  /* height: 34px; */
  height: 20px;
}

.quantity {
  font-size: 30px;
  font-weight: 400;
  color: #1A1A1A;
  text-align: center;
  margin-top: 13%;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #1a1a1a;
  margin-top: 10px;
}

.surveyHead {
  font-size: 20px;
  font-weight: 600;
  color: black;
  margin-left: 40px;
}

.surveyTable {
  margin-top: 20px;
  margin-left: 40px;
  width: 1296px;
  height: fit-content;
  max-height: 45%;
}

.surveyTable thead tr {
  height: 56px;
  background-color: '#CCCCCC' !important;
}

.surveyTable tbody tr {
  height: 52px;
}

.surveyTable td,
.surveyTable th {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #1A1A1A;
}
.surveyTable th {
  font-weight: 600;
}

.activeCard .card {
  cursor: pointer;
  border: solid 1px #003f2d;
  border-left: solid 6px #003f2d;
}

.pagination {
  list-style: none;
  text-align: right;
}

.pagination li {
  display: inline-block;
  margin-left: 30px;
}

.arrow {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  color: rgba(0, 0, 0, 0.54);
  padding: 3px;
  font-size: 16px;
  width: 2px;
  height: 2px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.pagination li div i:hover {
  font-size: 18px;
  cursor: pointer;
  color: #009f6f !important;
  width: 3px;
  height: 3px;
  border-width: 0 3px 3px 0;
}
.tooltip {
  cursor: pointer;
}
.surveyTooltip {
  visibility: hidden;
  width: 284px;
  height: fit-content;
  background-color: #2b3836;
  color: #fff;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.8px;

  /* Position the tooltip */
  position: absolute;
  margin-top: 5px;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -5px;
}
.surveyTooltip span {
  width: 100%;
}
.subText {
  color: lightgray;
  font-weight: 500 !important;
}

.tooltip:hover .surveyTooltip {
  visibility: visible;
}
.searchBox {
  margin-left: 40px;
  margin-top: 20px;
  position: relative;
}
.searchBox i {
  position: absolute;
  left: 15px;
  top: 40px;
  color: gray;
}
.searchInput {
  border-radius: 4px;
  border: 1px solid;
  padding: 10px;
}
.searchInput a:hover {
  background-color: #ddd;
  color: black;
}
