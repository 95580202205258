.locationDisplay {
  background: #ffffff;
  border: 1.5px solid #dfe3eb;
  box-sizing: border-box;
  border-radius: 7px;
  width: 260px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.location {
  text-decoration: none !important;
  align-self: center;
  cursor: pointer;
}

.location:hover {
  text-decoration: none !important;
}

.close {
  background: url('../../assets/images/closeIcon.svg') no-repeat;
}

h4 {
  margin: 0px;
}

.currentLocation {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0015em;
  color: #1a1a1a;
  text-decoration: none !important;
}

.currentLocation .current-location__icon {
  padding: 5px;
}

.placeName {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  letter-spacing: 0.15px;
  color: #1a1a1a;
}
.locationClose {
  align-self: center;
  padding: 8px;
}
