.modalBox {
  padding: 15px;
}

.surveyModalBox {
  width: 1000px;
}
.container1 {
  display: flex;
  width: 100%;
  flex: 1;
  gap: 24px;
}

.requestSurvey__title {
  font-size: 20px;
  font-weight: 500;
}

.requestSurvey__subtitle {
  font-size: 16px;
  font-weight: 400;
}

.fieldClient__block {
  height: 80px !important;
}

.mapRequest__container {
  top: 0px;
  border: 1px solid #ccc;
  border-radius: 7px;
}

.deskCountBlock {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  align-items: flex-end;
}

.field__container {
  min-height: 15px !important;
  margin: 0px !important;
}

.requestSurveyEmail {
  border: 1px solid rgba(26, 26, 26, 0.6) !important;
}

.datePickerInput {
  max-height: 48px;
}

.fieldError__block {
  font-size: 12px;
  color: #a03530;
  margin-top: 4px;
}

.fieldSuccess__block {
  height: 6px;
}

.minDeskCount__input {
  width: 30% !important;
  margin-left: 5px !important;
  margin-right: 8px !important;
  border: 1px solid rgba(26, 26, 26, 0.6) !important;
}

.maxDeskCount__input {
  width: 30% !important;
  margin-left: 4px !important;
  border: 1px solid rgba(26, 26, 26, 0.6) !important;
}

.container1 > div {
  display: flex;
  flex: 1;
}
.mapboxgl-ctrl-bottom-left {
  display: none;
}
::-webkit-calendar-picker-indicator {
  background: url('../../assets/images/calendar_month.svg') no-repeat;
}
select.selectdiv {
  background: url('../../assets/images/expand_more.svg') no-repeat;
  background-position: calc(100% - 7px) calc(1em + -10px), calc(100% - 15px) calc(1em + 2px),
    calc(100% - 0.5em) 0.5em;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  border: 1px solid rgba(26, 26, 26, 0.6) !important;
}

.chooseLocationBlock {
  margin-top: 8px;
}

.deskCountInput {
  border: 1px solid rgba(26, 26, 26, 0.6) !important;
}

.newClientName__input {
  border: 1px solid rgba(26, 26, 26, 0.6) !important;
}

.left-half {
  width: 50%;
}
.left-half h3 {
  margin-top: 0;
}

.deskCount {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 0 !important
}

.right-half {
  font-size: 14px;
  line-height: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
}

.right-half::-webkit-scrollbar {
  height: 84px;
  width: 4px;
}

.right-half::-webkit-scrollbar-thumb{
  background: rgba(26, 26, 26, 0.12);
  border-radius: 10px;
}
.right-half::-webkit-scrollbar-track{
  background: #E6EAEA;
}
.surveyFormContainer {
  flex-direction: column;
  gap: 8px;
}
.surveyFormContainer .field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.surveyFormContainer .field.deskcount {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

.surveyFormContainer .field input,
.surveyFormContainer .field select {
  margin: 0;
}
.deskcount .field {
  flex: 1;
}
.surveyFormContainer .geocoder {
  width: 100%;
}
.surveyFormContainer .geocoder .mapboxgl-ctrl-geocoder {
  max-width: 100%;
}
.surveyFormContainer .geocoder .mapboxgl-ctrl-geocoder--input {
  padding-left: 25px;
}

.surveyBtnField {
  margin-top: 15px;
  flex-direction: row !important;
}
.surveyBtnField button {
  flex: 1;
}
.mapRequest {
  height: 500px;
  border: 1px solid #cad0d8;
  box-sizing: border-box;
  border-radius: 7px;
  position: relative;
}

.mapRequest .mapboxgl-map {
  height: 100%;
}

.surveyHeading {
  font-family: Arial;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.0017em;
  color: #1a1a1a;
}
.geocoder3 {
  left: 53% !important;
  top: 55% !important;
  position: initial !important;
  border: 1px solid rgba(26, 26, 26, 0.6) !important;
}
.geocoder3 .mapboxgl-ctrl-geocoder {
  min-width: 372px !important;
  box-shadow: none !important;
}
.geocoder3 .mapboxgl-ctrl-geocoder--input {
  background: none !important;
}
/* //toggle button styles */
.switch {
  position: relative;
  width: 45px;
}

.switch .sliderInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: -1;
}

.slider:before {
  position: absolute;
  content: '';
  /* height: 26px;
  width: 26px; */
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderInput:checked + .slider {
  background-color: #003f2d;
}

.sliderInput:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.sliderInput:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#editor-container {
  height: 375px;
}

.editor-wrapper {
  position: relative;
}

.ql-toolbar {
  width: 100%;
  z-index: 1;
}
.ql-editor {
  border: 1px solid #ccc;
  height: 106px !important;
}
.ql-container {
  border-radius: 5px;
  /* height:20% !important; */
  position: inherit;
  height: auto !important;
}
.ql-container.ql-snow {
  border: none !important;
}
.ql-snow .ql-tooltip {
  margin-left: 140px !important;
}
.sliderCheck {
  display: flex;
  gap: 8px;
}
