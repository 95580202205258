.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}
.ReactModal__Content {
  transform: translate(-50%, -100%);
  transition: transform 0.8s ease-out;
}
::-webkit-scrollbar{height: 0;}
.ReactModal__Content--after-open {
  transform: translate(-50%, -50%);
  transition: transform 0.8s ease-out;
  overflow: auto !important;
  padding: 28px 20px 20px !important;
  
}
.ReactModal__Overlay {
  opacity: 0.5;
  transition: opacity 0.2ms ease-out;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.modal-close {
  position: absolute;
  font-weight: bold;
  font-size: 16px;
  color: #1a1a1a;
  top: 6px;
  right: 4px;
  border: none;
  background: none;
  cursor: pointer;
}
