.market-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 450px;
}

.modalStyle {
  border: 5px;
  width: 433px;
  margin-left: 400px;
  z-index: inherit;
}
.requestModal {
  outline: none;
  position: absolute;
  border: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}
h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  letter-spacing: -0.05px;
  color: #1a1a1a;
}
.market-container h3,
.market-container p {
  margin: 0;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #1a1a1a;
}
.modalBox {
  padding: 30px;
  padding-top: 15px;
}

.clickable {
  right: 12px;
  top: 5px;
  position: absolute;
  cursor: pointer;
}

.requestMarket__title {
  font-size: 22px;
}

.requestTextField {
  margin: 25px 0;
}

.requestMarketError__block {
  font-size: 11px;
  color: #a03530;
}
.field input,
.field select {
  border-radius: 5px;
  width: 100%;
  height: 33px;
  padding: 8px;
  margin-bottom: 4px;
  margin-top: 3px;
}
.space {
  padding-top: 8px;
  padding-bottom: 8px;
}
.btnField {
  margin-top: 32px;
  display: flex;
  gap: 16px;
}
.btnField button {
  flex: 1;
}
.requestsubmitbtn {
  height: 40px;
  width: 150px;
  background: #009f6f;
  box-shadow: 0px 1px 2px rgba(0, 114, 81, 0.25);
  border-radius: 7px;
  margin-left: 20px;
  color: #fff;
  border: 0;
}
.s {
  margin-right: 20px;
}
.cancelbtn {
  height: 40px;
  width: 90px;
  background: #fff;
  box-shadow: 0px 1px 2px rgba(0, 114, 81, 0.25);
  border-radius: 7px;
  margin-left: 20px;
  color: #009f6f;
  border: 0;
}
.loginLabel {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
}
.Toastify {
  inset: 0;
  background-color: rgba(196, 196, 196, 0.5);
}
.Toastify__progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: #009f6f;
  transform-origin: left;
}

.astrisk {
  color: red;
  font-size: 15px;
}

.textareaField textarea {
  border-radius: 5px;
  height: 150px;
  width: 372px;
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  margin-top: 3px;
}
.modalLabel {
  display: flex;
}

textarea,
select {
  outline: none;
}
