.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.LoadingSpinner_spinner {
  position: absolute;
}
.iframe {
  opacity: 0.5;
}
.iframe_afterload {
  transition: ease-out 0.8s;
  opacity: 1;
}
