.refreshLogin__btn {
    margin-left: 45%;
}

.spinner__container {
    text-align: center;
    margin-top: 30px;
    left: 0 !important;
}

.spinner {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50%;
    top: 40%;
}
  
.spinner:after {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #009f6f;
    border-color: #009f6f transparent;
    animation: spinner 1.2s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  