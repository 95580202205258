/* LEFTSIDE BAR STYLEs */

.leftSideBar {
  min-height: 97%;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  width: 24%;
}
.searchBar {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  height: 60px;
}
.priceDiv {
  height: 60px;
}
.centerText {
  text-align: center;
  padding: 8px 16px;
}

.surveyDiv {
  z-index: 25;
  width: 25%;
}

/* Right column */
.leftcolumn {
  padding: 10px 25px 10px 25px;
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 9rem);
}
.leftcolumn::-webkit-scrollbar {
  display: none;
}
.requestBtn {
  cursor: pointer;
  padding: 10px 0px;
  background: #009f6f;
  color: #fff;
  width: 100%;
  margin: 10px auto;
  border: 0;
  border-radius: 4px;
  width: 187px;
}
.requestBtn:disabled,
.requestBtn[disabled] {
  background: #d7dfdd;
  box-shadow: 0px 1px 2px rgba(0, 114, 81, 0.25);
  border-radius: 7px;
}
.requestSurveyBtn {
  background: #009f6f;
  cursor: pointer;
  padding: 12px 20px;
  color: #fff;
  background: #009f6f;
  box-shadow: 0px 1px 2px rgba(0, 114, 81, 0.25);
  border-radius: 7px;
  border: 0;
}

.requestSurveyBtn:disabled,
.requestSurveyBtn[disabled] {
  background: #d7dfdd;
  box-shadow: 0px 1px 2px rgba(0, 114, 81, 0.25);
  border-radius: 7px;
}

.tooltip2 {
  position: relative;
  display: inline-block;
}

.tooltip2 .tooltiptext2 {
  visibility: hidden;
  width: 250px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0px;
  font-size: 14px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 48px;
  left: 158px;
}

.tooltip2:hover .tooltiptext2 {
  visibility: visible;
}

.tooltip__position {
  top: -26px;
  padding: 5px 5px;
  left: 5px;
}

/* Fake image */
.fakeimg {
  background-color: #aaa;
  width: 100%;
  /* padding: 20px; */
}

.providerDisplay {
  padding: 25px;
}

/* Add a card effect for articles */
.card {
  padding: 1px;
}

.card__except {
  height: 130px;
}

table {
  /* font-family: arial, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border-bottom: 1px solid #d9e1e0;
  text-align: left;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.1px;
}
.card p {
  text-align: left;
  color: #1a1a1a;
  font-weight: 400;
  font-size: 14px;
  line-height: 0px;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

/* //checkbox styles */
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border: 2px solid #ccc;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.boldPrice {
  color: #1a1a1a;
  font-family: GraphikBlack;
  font-size: 20px;
  line-height: 1;
}

/* //PRODUCTS CSS */
.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.checkbox {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin: 5px 0px;
}
input[type='checkbox'] {
  display: none;
}
.notAvail {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 31px;
  color: #1a1a1a;
}
.avail {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  line-height: 31px;
  color: #003f2d;
  font-weight: bold;
}

.avail img,
.notAvail img {
  position: relative;
  display: inline-block;
  top: 5.1px;
  margin-right: 2px;
}

.boldProduct {
  color: #1a1a1a;
  font-family: GraphikBlack;
}

.boldText {
  color: #1a1a1a;
  font-weight: bold;
}

input[type='checkbox']:checked ~ .boldProcuct {
  font-weight: bold;
  color: #1a1a1a;
}
.checkbox__icon:before {
  font-family: 'icons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .icon--check:before, input[type="checkbox"]:checked ~ .checkbox__icon:before {
  content: "\e601"; 
}

.icon--check-empty:before, .checkbox__icon:before {
   content: "\e600";
} */

@font-face {
  font-family: 'icons';
  font-weight: normal;
  font-style: normal;
  src: url('data:application/x-font-woff;charset=utf-8;base64,d09GRk9UVE8AAAR4AAoAAAAABDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABDRkYgAAAA9AAAAPgAAAD4fZUAVE9TLzIAAAHsAAAAYAAAAGAIIvy3Y21hcAAAAkwAAABMAAAATBpVzFhnYXNwAAACmAAAAAgAAAAIAAAAEGhlYWQAAAKgAAAANgAAADYAeswzaGhlYQAAAtgAAAAkAAAAJAPiAedobXR4AAAC/AAAABgAAAAYBQAAAG1heHAAAAMUAAAABgAAAAYABlAAbmFtZQAAAxwAAAE5AAABOUQYtNZwb3N0AAAEWAAAACAAAAAgAAMAAAEABAQAAQEBCGljb21vb24AAQIAAQA6+BwC+BsD+BgEHgoAGVP/i4seCgAZU/+LiwwHi2v4lPh0BR0AAAB8Dx0AAACBER0AAAAJHQAAAO8SAAcBAQgPERMWGyBpY29tb29uaWNvbW9vbnUwdTF1MjB1RTYwMHVFNjAxAAACAYkABAAGAQEEBwoNL2X8lA78lA78lA77lA6L+HQVi/yU+JSLi/iU/JSLBd83Fffsi4v77Pvsi4v37AUOi/h0FYv8lPiUi4v33zc3i/s3++yLi/fs9zeL398F9wCFFftN+05JzUdI9xr7GveR95FHzwUO+JQU+JQViwwKAAMCAAGQAAUAAAFMAWYAAABHAUwBZgAAAPUAGQCEAAAAAAAAAAAAAAAAAAAAARAAAAAAAAAAAAAAAAAAAAAAQAAA5gEB4P/g/+AB4AAgAAAAAQAAAAAAAAAAAAAAIAAAAAAAAgAAAAMAAAAUAAMAAQAAABQABAA4AAAACgAIAAIAAgABACDmAf/9//8AAAAAACDmAP/9//8AAf/jGgQAAwABAAAAAAAAAAAAAAABAAH//wAPAAEAAAAAAACkYCfgXw889QALAgAAAAAAz65FuwAAAADPrkW7AAD/4AIAAeAAAAAIAAIAAAAAAAAAAQAAAeD/4AAAAgAAAAAAAgAAAQAAAAAAAAAAAAAAAAAAAAYAAAAAAAAAAAAAAAABAAAAAgAAAAIAAAAAAFAAAAYAAAAAAA4ArgABAAAAAAABAA4AAAABAAAAAAACAA4ARwABAAAAAAADAA4AJAABAAAAAAAEAA4AVQABAAAAAAAFABYADgABAAAAAAAGAAcAMgABAAAAAAAKACgAYwADAAEECQABAA4AAAADAAEECQACAA4ARwADAAEECQADAA4AJAADAAEECQAEAA4AVQADAAEECQAFABYADgADAAEECQAGAA4AOQADAAEECQAKACgAYwBpAGMAbwBtAG8AbwBuAFYAZQByAHMAaQBvAG4AIAAxAC4AMABpAGMAbwBtAG8AbwBuaWNvbW9vbgBpAGMAbwBtAG8AbwBuAFIAZQBnAHUAbABhAHIAaQBjAG8AbQBvAG8AbgBHAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4AAAAAAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA==')
    format('woff');
}
.label-to-bold-if-checked:checked + span {
  font-weight: bold;
}
.pricing {
  height: 76px;
}
.pricing .row {
  width: 115%;
}
.pricing .row:after {
  content: '';
  display: table;
  clear: both;
}

.pricing .column {
  float: left;
  width: 33.33%;
}

.pricing__type {
  color: #1a1a1a;
}

.surveyPos {
  margin-left: 112px;
}

@media screen and (min-width: 1024px) {
  .leftsidebar {
    width: 23.7%;
  }
  .leftSideBar {
    min-height: 94%;
  }
  td,
  th {
    border-bottom: 1px solid #d9e1e0;
    text-align: left;
    font-size: 12px;
    line-height: 23px;
    /* letter-spacing: 0.10px; */
  }
  .boldProcuct {
    font-size: 14px;
    line-height: 25px;
  }
}

@media screen and (min-width: 1440px) {
  .leftSideBar {
    min-height: 95%;
    width: 24%;
  }
}

button,
input {
  outline: none;
}

.table__container {
  height: 30vh;
}
.table__container table {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  text-align: left;
  background: #fff;
}
.table__container table thead {
  flex: 0 0 auto;
}

.table__container table tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.table__container table tbody::-webkit-scrollbar {
  height: 84px;
  width: 4px;
}

.table__container table tbody::-webkit-scrollbar-thumb{
  background: rgba(26, 26, 26, 0.12);
  border-radius: 10px;
}
.table__container table tbody::-webkit-scrollbar-track{
  background: #E6EAEA;
}

.table__container table tbody tr {
  width: 100%;
  text-align: center;
}
.table__container table thead,
.table__container table tbody tr {
  display: table;
  border-bottom: 1px solid #e5e5e5;
  background-color: #fbfbfb;
}
.table__container tbody tr:hover {
  border: 1px solid #009f6f;
}
.table__container tbody .responded {
  border-left: 1px solid #009f6f;
  font-weight: bold;
}
.table__container table thead tr {
  text-align: center;
}

.table__container table thead th {
  padding-right: 0;
  font-size: 14px;
}
.tableView__container{
  background: #E5E5E5;
  height: 36vh;
}

.tableView__container table {
  height: 30vh;
}
.table__container table thead td {
  line-height: 10px !important;
  font-size: 14px;
}

.provider__details {
  display: flex;
  align-items: center;
  padding: 0;
  line-height: 2px;
  padding: 5px 0;
}

.providerDetails__text {
  float: left;
}
.providerTotal__table {
  height: 6vh;
  border-top: 1px solid #ccc;
  border-bottom: none;
  background: #FBFBFB;
}

.providersTitle__name {
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-align: left;
  padding: 8px 0;
}

.providerTitle__locations {
  font-size: 16px !important;
  font-weight: 500 !important;
  border: none !important;
  text-align: right;
  padding: 8px 0;
}

.providerTableHead__subtitle {
  padding: 8px 0;
  color: #1a1a1a;
  font-size: 16px;
  font-family: Calibre-R;
  font-weight: 500;
  border: none;
}