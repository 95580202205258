/* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* mdl-demo is a custom classname to increases the specificity of our styles. It can be anything. 
   * The idea is that it is easy to extend/override builtin styles with very little effort.
   */

.mdl-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}
.mdl-demo .dropdown-trigger {
  border-radius: 4px;
}

.mdl-demo .toggle {
  font: normal normal normal 18px/1 'Material Icons';
  color: #555;
  white-space: pre;
  margin-right: 4px;
}

.mdl-demo .toggle.collapsed::after {
  cursor: pointer;
  /* content: '\E5CF'; */
  vertical-align: middle;
}

.mdl-demo .toggle.expanded::after {
  cursor: pointer;
  /* content: '\E5CE'; */
  vertical-align: middle;
}
.mdl-demo input[type='checkbox'] {
  display: inline-block;
}
.mdl-demo .dropdown-content {
  right: auto;
}

/* checkbox styles */
.mdl-demo .checkbox-item {
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  vertical-align: middle;
}

.mdl-demo .dropdown {
  background: #fff;
  width: 98%;
}

.mdl-demo .checkbox-item:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 2px solid #aaa;
  transition: all 0.3s ease-in-out;
}

.mdl-demo .checkbox-item:checked:before {
  height: 50%;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top-style: none;
  border-right-style: none;
  border-color: #1a1a1a;
}
.mdl-demo .react-dropdown-tree-select .dropdown .dropdown-trigger {
  padding: 0px;
  line-height: 20px;
  max-height: 100px;
  display: inline-block;
  overflow: auto;
  border: 1px solid #b9b9b9;
  border-radius: 4px;
  background: #fff;
  width: 100%;
  padding-right: 0px;
}
.dropdown-trigger ul {
  padding: 10px;
}
.dropdown-trigger ul {
  width: 300px;
}
.mdl-demo .dropdown-content {
  width: 100%;
}
.mdl-demo .dropdown a {
  width: 99%;
  padding: 0px;
  text-decoration: none;
  background: #fff;
}
.mdl-demo .dropdown-trigger input {
  min-width: 280px;
  background: none;
  padding: 7.3px;
}
.mdl-demo .dropdown-trigger input::placeholder {
  color: #aaa;
  font-size: 14px;
  font-style: normal;
}
.tag-item .search {
  border: none;
}
.tag-item {
  /* width:90%; */
}
.mdl-demo .dropdown-content ul {
  overflow-y: auto;
  max-height: 500px;
}
.mdl-demo .dropdown-content {
  max-width: 99%;
}

.tag-item .search {
  border-bottom: none !important;
}

.MuiInputBase-formControl {
  background: #fff;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  display: none;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  display: none;
}
.clearSearchBtn {
  margin-top: 10px;
  margin-left: auto;
  display: block;
  background: #003f2d;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.clearSearchBtn:disabled {
  background-color: rgba(0,0,0,0.12);
  color: rgba(0,0,0,0.38);
  border: 1px solid rgba(0,0,0,0);
  cursor: default;
  pointer-events: none;
}

.closeBtn {
  right: '177px';
  position: 'absolute';
  z-index: '1000';
  top: '99px';
}

/* .mdl-demo input.checkbox-item {
  border: 2px solid #aaa;
  padding: 4px;
  width: 17px;
  height: 17px;
} */

/* .mdl-demo .checkbox-item:checked:before {
  content: '\2714';
} */

/* .tag {
  position: absolute;
  top: 84px;
} */
/* //styles for display selected options below  */
/* .tag-item:last-child {
  position: absolute;
  left: 507px;
  bottom: 170px;
}

.dropdown a {
  height: 41px;
}
.dropdown-trigger ul {
  width: 95%;
}

.tag-list {
  position: absolute;
  top: 82px;
  right: 513px;
} */
