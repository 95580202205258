.providerBlock_icon {
    float: left;
}

.provider__icon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

.providerTotal__title {
  border: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  border-bottom: none;
  padding: 8px 0px;
}

.providerTotal__text {
  border: none !important;
  border-bottom: none;
  font-weight: 500 !important;
  font-size: 16px !important;
  padding: 8px 0px;
}

.total__title {
  border: 0px;
}

.total__text {
  border: 0px;
  padding: 2px 0px;
}

.informationBlock__title {
  padding: 8px 0;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #1a1a1a;
}