.chart-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.chart-title {
  text-align: center;
  margin: 0px;
  font-size: 18px;
  height: 20px;
}
.dealchart {
  flex: 1;
}
