.form-inline {  
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  .form-inline label {
    margin: 5px 10px 5px 0;
  }

  .form-inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
  }

  .button-section {
    margin: 3%;
  }
  .button {
    padding: 10px 20px;
    background-color: dodgerblue;
    border: 1px solid #ddd;
    color: white;
    cursor: pointer;
  }

  .button:hover {
    background-color: royalblue;
  }

  .remove {
    background-color: rgb(192, 53, 53);
  }
  .remove:hover {
    background-color: rgb(187, 43, 43);
  }

  .paginationBlock {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}
.pagec{
  margin-top: 10px;
}
.buttonc{
  display: flex;
    gap: 10px;
    margin-top: 10px;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
  /* width: 80% !important;
  height: 50% !important; */
}

.provider__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}

.provider__container__load {
  margin-top: 1em;
  color: white;
}

.provider__container__document {
  width: 100%;
  max-width: calc(100% - 2em);
  margin: 1em 0;
}

.provider__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.provider__container__document .react-pdf__Page {
  margin: 1em 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.provider__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}
