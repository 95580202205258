.surveyTable tr:nth-child(even) {
  background-color: #f6f6f6;
}

.surveyTable .rightAlign {
  text-align: right !important;
}

.MuiTablePagination-selectRoot {
  margin-top: 2px;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}